import React, { useState, useEffect } from 'react';

const CONTRACT_ACCOUNT = 'leefadsplace';
const TOKEN_CONTRACT = 'leefmaincorp';

const SubmitAd = ({ session, theme, onSubmit }) => {
  const [content, setContent] = useState('');
  const [targetUrl, setTargetUrl] = useState('');
  const [payment, setPayment] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState('input'); // 'input', 'transfer', 'submit'
  const [isAdmin, setIsAdmin] = useState(false);
  const [pendingAds, setPendingAds] = useState([]);
  const [imageErrors, setImageErrors] = useState({});

  useEffect(() => {
    if (session) {
      checkAdminStatus();
      if (isAdmin) {
        fetchPendingAds();
      }
    }
  }, [session, isAdmin]);

  const checkAdminStatus = async () => {
    try {
      const result = await session.client.v1.chain.get_table_rows({
        json: true,
        code: CONTRACT_ACCOUNT,
        scope: CONTRACT_ACCOUNT,
        table: 'admins',
        lower_bound: session.actor,
        upper_bound: session.actor,
        limit: 1,
      });
      setIsAdmin(result.rows.length > 0 && result.rows[0].is_admin);
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  };

  const fetchPendingAds = async () => {
    try {
      const result = await session.client.v1.chain.get_table_rows({
        json: true,
        code: CONTRACT_ACCOUNT,
        scope: CONTRACT_ACCOUNT,
        table: 'ads',
        limit: 100,
      });
      setPendingAds(result.rows.filter(ad => !ad.is_approved));
    } catch (error) {
      console.error('Error fetching pending ads:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!session) {
      setError("Please login first");
      return;
    }
    if (!content || !targetUrl || !payment) {
      setError("Please fill in all fields");
      return;
    }
    setStep('transfer');
  };

  const handleTransfer = async () => {
    setIsSubmitting(true);
    setError('');

    try {
      const paymentAmount = `${parseFloat(payment).toFixed(4)} LEEF`;
      
      const transferAction = {
        account: TOKEN_CONTRACT,
        name: 'transfer',
        authorization: [{ actor: session.actor, permission: 'active' }],
        data: {
          from: session.actor,
          to: CONTRACT_ACCOUNT,
          quantity: paymentAmount,
          memo: 'Ad payment',
        },
      };

      console.log('Submitting transfer action:', transferAction);
      const transferResult = await session.transact({ actions: [transferAction] });
      console.log('Transfer result:', transferResult);
      setStep('submit');
    } catch (error) {
      console.error('Token transfer failed:', error);
      handleTransactionError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAdSubmission = async () => {
    setIsSubmitting(true);
    setError('');

    try {
      const paymentAmount = `${parseFloat(payment).toFixed(4)} LEEF`;

      const submitAdAction = {
        account: CONTRACT_ACCOUNT,
        name: 'submitad',
        authorization: [{ actor: session.actor, permission: 'active' }],
        data: {
          advertiser: session.actor,
          content: content,
          target_url: targetUrl,
          payment: paymentAmount,
        },
      };

      console.log('Submitting ad action:', submitAdAction);
      const submitResult = await session.transact({ actions: [submitAdAction] });
      console.log('Ad submitted successfully:', submitResult);

      onSubmit();
      setContent('');
      setTargetUrl('');
      setPayment('');
      setStep('input');
    } catch (error) {
      console.error('Ad submission failed:', error);
      handleTransactionError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleApproveAd = async (adId) => {
    setIsSubmitting(true);
    setError('');

    try {
      const approveAdAction = {
        account: CONTRACT_ACCOUNT,
        name: 'approvead',
        authorization: [{ actor: session.actor, permission: 'active' }],
        data: {
          ad_id: adId,
          admin: session.actor,
        },
      };

      console.log('Approving ad:', approveAdAction);
      const approveResult = await session.transact({ actions: [approveAdAction] });
      console.log('Ad approved successfully:', approveResult);

      fetchPendingAds();
    } catch (error) {
      console.error('Ad approval failed:', error);
      handleTransactionError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTransactionError = (error) => {
    if (error.message.includes('Unable to open the popup window')) {
      setError('Unable to open the signing window. Please ensure popups are allowed for this site and try again. If you\'re using a popup blocker, you may need to disable it for this site.');
    } else {
      setError(`Transaction failed: ${error.message}`);
    }
  };

  const handleImageError = (adId) => {
    setImageErrors(prev => ({ ...prev, [adId]: true }));
  };

  const renderStepContent = () => {
    switch (step) {
      case 'transfer':
        return (
          <div>
            <p style={{ color: theme.textColor, marginBottom: '15px' }}>
              Please confirm the token transfer of {payment} LEEF in the popup window.
            </p>
            <button 
              onClick={handleTransfer}
              disabled={isSubmitting}
              style={buttonStyle}
            >
              {isSubmitting ? 'Processing...' : 'Transfer Tokens'}
            </button>
          </div>
        );
      case 'submit':
        return (
          <div>
            <p style={{ color: theme.textColor, marginBottom: '15px' }}>
              Tokens transferred successfully. Please confirm the ad submission.
            </p>
            <button 
              onClick={handleAdSubmission}
              disabled={isSubmitting}
              style={buttonStyle}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Ad'}
            </button>
          </div>
        );
      default:
        return (
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="content" style={labelStyle}>
                Ad Content
              </label>
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Enter your redirect url here"
                required
                style={textareaStyle}
              />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="targetUrl" style={labelStyle}>
                Photo URL
              </label>
              <input
                id="targetUrl"
                type="url"
                value={targetUrl}
                onChange={(e) => setTargetUrl(e.target.value)}
                placeholder="https://example.png"
                required
                style={inputStyle}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="payment" style={labelStyle}>
                Payment (LEEF)
              </label>
              <input
                id="payment"
                type="number"
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                placeholder="Enter amount"
                required
                step="0.0001"
                min="0.0001"
                style={inputStyle}
              />
            </div>
            <button 
              type="submit" 
              disabled={isSubmitting}
              style={buttonStyle}
            >
              Proceed to Submit
            </button>
          </form>
        );
    }
  };

  const renderAdminSection = () => {
    if (!isAdmin) return null;

    return (
      <div style={{ marginTop: '30px' }}>
        <h3 style={headingStyle}>Admin Panel - Pending Ads</h3>
        {pendingAds.length === 0 ? (
          <p style={{ color: theme.textColor }}>No pending ads at the moment.</p>
        ) : (
          pendingAds.map(ad => (
            <div key={ad.id} style={adCardStyle}>
              {!imageErrors[ad.id] ? (
                <img 
                  src={ad.target_url} 
                  alt="Ad Image" 
                  style={imageStyle}
                  onError={() => handleImageError(ad.id)}
                />
              ) : (
                <div style={{
                  ...imageStyle,
                  backgroundColor: theme.backgroundSecondary,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: theme.textColor
                }}>
                  Image unavailable
                </div>
              )}
              <p style={{ color: theme.textColor, marginBottom: '10px' }}>
                <strong>Content:</strong> {ad.content}
              </p>
              <p style={{ color: theme.textColor, marginBottom: '15px' }}>
                <strong>Payment:</strong> {ad.payment}
              </p>
              <button
                onClick={() => handleApproveAd(ad.id)}
                disabled={isSubmitting}
                style={{
                  ...buttonStyle,
                  opacity: isSubmitting ? 0.7 : 1,
                  cursor: isSubmitting ? 'not-allowed' : 'pointer'
                }}
              >
                {isSubmitting ? 'Processing...' : 'Approve Ad'}
              </button>
            </div>
          ))
        )}
      </div>
    );
  };

  const containerStyle = {
    backgroundColor: theme.cardBackground,
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  };

  const headingStyle = { 
    color: theme.textColor, 
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center'
  };

  const errorStyle = {
    backgroundColor: '#ffebee',
    color: '#c62828',
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '20px',
    fontSize: '14px',
  };

  const labelStyle = { 
    display: 'block', 
    marginBottom: '5px', 
    color: theme.textColor,
    fontSize: '14px'
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: `1px solid ${theme.borderColor}`,
    backgroundColor: theme.cardBackground,
    color: theme.textColor,
    fontSize: '14px',
  };

  const textareaStyle = {
    ...inputStyle,
    minHeight: '100px',
    resize: 'vertical',
  };

  const buttonStyle = {
    width: '100%',
    padding: '12px',
    backgroundColor: isSubmitting ? theme.borderColor : theme.buttonBackground,
    color: theme.buttonText,
    border: 'none',
    borderRadius: '5px',
    cursor: isSubmitting ? 'not-allowed' : 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  };

  const adCardStyle = {
    backgroundColor: theme.highlightBackground,
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '15px',
  };

  const imageStyle = {
    width: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    marginBottom: '10px',
    borderRadius: '5px',
  };

  return (
    <div style={containerStyle}>
      <h3 style={headingStyle}>Submit New Ad</h3>
      {error && (
        <div style={errorStyle}>
          {error}
        </div>
      )}
      {renderStepContent()}
      {renderAdminSection()}
    </div>
  );
};

export default SubmitAd;
